import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import List from "../../../components/custom-widgets/list";
import Icon from "../../../components/custom-widgets/icon";
import showMktoForm from "../../../helpers/showMktoForm";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const SeniorHousing = () => {
  const title = "Senior Living Industry Commercial Banking";
  const description =
    "Learn about WaFd Bank and how it supports the senior living industry with multiple financing options, deposit and treasury services and more.";
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/senior-housing/hero-senior-housing-01102023-XXL.jpg"
        }
      ) {
        ...heroImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/senior-housing/hero-senior-housing-01102023-XL.jpg"
        }
      ) {
        ...heroImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/senior-housing/hero-senior-housing-01102023-LG.jpg"
        }
      ) {
        ...heroImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/senior-housing/hero-senior-housing-01102023-MD.jpg"
        }
      ) {
        ...heroImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/senior-housing/hero-senior-housing-01102023-SM.jpg"
        }
      ) {
        ...heroImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/senior-housing/hero-senior-housing-01102023-XS.jpg"
        }
      ) {
        ...heroImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/senior-housing/hero-senior-housing-01102023-XXS.jpg"
        }
      ) {
        ...heroImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/specialized-industries/senior-housing"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-senior-housing-01102023.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "senior-housing-hero",
    ...getHeroImgVariables(imgData),
    chevronBgClass: "bg-dark-green-01",
    altText: "Senior housing building with tropical trees on a sunny day.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Services for the Business of Senior Living"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      url: "/commercial-banking/specialized-industries",
      title: "Specialized Industries"
    },
    {
      id: 3,
      active: true,
      title: "Senior Housing"
    }
  ];

  const depositTreasuryServicesList = {
    greenCircleCheckmark: true,
    idList: 1,
    accountName: "deposit-treasury-services",
    items: [
      {
        id: 1,
        text: "Remote Deposit"
      },
      {
        id: 2,
        text: "ACH Debit for collecting monthly resident payments"
      },
      {
        id: 3,
        text: "ACH Credit for vendor payment &amp; bank transfers"
      },
      {
        id: 4,
        text: "Consolidated Payables to streamline payables"
      },
      {
        id: 5,
        text: "Positive Pay"
      }
    ]
  };

  const businessTermLoansList = {
    greenCircleCheckmark: true,
    idList: 2,
    accountName: "business-term-loans",
    items: [
      {
        id: 1,
        text: "Construction financing"
      },
      {
        id: 2,
        text: "Private placement tax-exempt bonds"
      },
      {
        id: 3,
        text: "Lines of Credit"
      },
      {
        id: 4,
        text: "Letters of Credit"
      }
    ]
  };

  const whyWafdBankList = {
    idList: 3,
    accountName: "why-wafd-bank",
    items: [
      {
        id: 1,
        text: "We support both the not-for-profit and for-profit sectors of the senior living industry"
      },
      {
        id: 2,
        text: "We work with the largest not-for-profit operators in Washington, Oregon, and California"
      },
      {
        id: 3,
        text: "We work with the largest for-profit operator based in Arizona"
      },
      {
        id: 4,
        text: "We have the knowledge and experience needed to support the industry in each of the markets we serve"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Senior Housing</h1>
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <StaticImage
              src="../../../images/thumbnail-retirement-031224.jpg"
              alt="Mature couple sitting on their veranda."
              quality="100"
            />
          </div>
          <div className="col-md-6">
            <h2 className="text-success">
              Looking to finance the Acquisition, Rehabilitation, Construction, or Recapitalization of an Assisted
              Living Facility?
            </h2>
            <p>
              Our population is living longer; and maintaining a higher standard of living through the golden years.
              WaFd Bank understands the full spectrum of the market and the need for both affordable and luxury
              retirement communities.
            </p>
            <a
              id="contact-commercial-banker-form-cta"
              className="btn btn-primary w-100 w-sm-auto no-min-width"
              href="/#"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                showMktoForm(1067);
              }}
            >
              Contact Us
            </a>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <h2 className="text-success">Business Solutions</h2>
          <div className="row">
            <div className="col-md-6">
              <h4 className="font-weight-bold">Deposit &amp; Treasury Services</h4>
              <List {...depositTreasuryServicesList} />
            </div>
            <div className="col-md-6">
              <h4 className="font-weight-bold">Business Term Loans</h4>
              <List {...businessTermLoansList} />
              <h4 className="font-weight-bold">Additional Industry Resources</h4>
              <p>
                <a
                  href="https://seniorhousingnews.com/"
                  className="text-decoration-none"
                  rel="noopener noreferrer"
                  target="_blank"
                  id="external-link-seniorhousingnews"
                >
                  Senior Housing News
                  <Icon name="arrow-right" class="ml-1" />
                </a>
              </p>
              <p className="mb-0">
                <a
                  href="https://www.leadingage.org/"
                  className="text-decoration-none"
                  rel="noopener noreferrer"
                  target="_blank"
                  id="external-link-leadingage"
                >
                  Leading Age
                  <Icon name="arrow-right" class="ml-1" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <h2 className="text-success">Why WaFd Bank</h2>
            <List {...whyWafdBankList} />
          </div>
          <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0">
            <StaticImage
              src="../../../images/thumbnail-why-wafd-031224.jpg"
              alt="Business meeting with smiling businesswoman and businessman."
              quality="100"
              className="border-radius-12"
            />
          </div>
        </div>
      </section>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default SeniorHousing;
